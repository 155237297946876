import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as Types from './types';

//
//

export class ManagerProductsComplementary extends ZidderResource<ManagerProductsComplementary> {
  protected classInstance = ManagerProductsComplementary;
  public pathBase = 'v1/products/';

  //

  async complementaryProductsGet(
    query: Types.GetComplementaryProductsQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const { id } = query;

    const response = await this.client.get(this.pathBase + id + '/complementary-products', {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<Types.GetComplementaryProductsResponse>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async complementaryProductsUpdate(
    payload: Types.UpdateComplementaryProductsPayload,
    options?: ZidderRequestOptions,
  ) {
    const { id, products } = payload;

    const response = await this.client.post(this.pathBase + id + '/complementary-products', {
      json: { products },
      ...options,
    });

    if (!response?.ok) {
      this.throwUnexpectedResponse(response);
    }

    return response.ok;
  }
}

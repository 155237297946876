import { ZidderRequestOptions } from '~/zidderjs';
import { ZidderResource } from '~/zidderjs/classes';
import { prepareFormDataForMultipleKeys } from '~/zidderjs/utils';

import * as SettingsInformationKYCTCreateTypes from './create.types';
import * as SettingsInformationKYCTypes from './types';

export class ManagerKycSchema extends ZidderResource<ManagerKycSchema> {
  protected classInstance = ManagerKycSchema;

  public path = 'v1/managers/store/kyc-schema';

  async get(
    business_type: SettingsInformationKYCTypes.KycTypesQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.path, {
      ...options,
      searchParams: { ...business_type, ...options?.searchParams },
    });

    const result =
      await response.json<SettingsInformationKYCTypes.SettingsInformationKYCSchemaResponse>();

    if (!result?.schema) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    data: SettingsInformationKYCTCreateTypes.PayloadInformationKYC,
    options?: ZidderRequestOptions,
  ): Promise<SettingsInformationKYCTypes.ResponseInformationKYC> {
    const body = prepareFormDataForMultipleKeys(data);

    const response = await this.client.post(this.path, {
      ...options,
      body: body,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<SettingsInformationKYCTypes.ResponseInformationKYC>();

    if (!result?.status) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
